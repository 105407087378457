import * as React from 'react';
import FlexView from 'react-flexview';
import styled from 'styled-components';
import {
  desktopMixin,
  mobileAndTabletMixin,
  mobileMixin,
  tabletMixin,
} from 'styles/themeResponsiveMixin';
import DoodleAsset from './DoodleAsset';

const ErrorPageStyle = styled(FlexView)`
  background: #ffffff;
  position: relative;

  ${mobileAndTabletMixin`
    justify-content: flex-start !important;
  `}

  .error-page-content {
    width: 90%;
    max-width: 1170px;
    background: white;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
    border-radius: 9px;
    margin: 70px;
    position: relative;
    padding: 40px;

    a {
      margin-top: 10px;
      text-decoration: underline;
    }

    ${desktopMixin`
      height: 480px;
      a {
        font-size: 20px;
      }
    `}

    ${mobileMixin`
      flex-direction: column-reverse !important;
      margin: 40px 10px;
      width: unset !important;
      padding: 200px 40px 40px;
    `}

    ${tabletMixin`
      flex-direction: column-reverse !important;
      margin: 50px;
      width: 90%;
      padding: 300px 40px 40px;
    `}


    &-item {
      width: 50%;

      ${mobileMixin`
        width: 100% !important;
        h1,
        p,
        a,
        .status {
          text-align: center;
        }

        .status {
          margin-top: 20px !important;
        }
      `}

      ${tabletMixin`
        width: 100% !important;
        h1,
        p,
        a,
        .status {
          text-align: center;
        }

        .status {
          margin-top: 20px !important;
        }
      `}
    }

    .doodle-asset {
      width: 400px;
      height: auto;
      position: absolute;
      top: -35px;

      ${mobileMixin`
        width: 164px;
        top: -28px;
      `}

      ${tabletMixin`
        width: 250px;
        top: -28px;
      `}
    }
  }
`;
interface IErrorPage {
  statusCode: '404' | '500';
  errorTitle: string;
  errorHint: string;
}

class ErrorPage extends React.PureComponent<IErrorPage> {
  render() {
    const { statusCode, errorTitle, errorHint } = this.props;
    return (
      <ErrorPageStyle
        width="100%"
        column
        className="error-page"
        hAlignContent="center"
        vAlignContent="center"
      >
        <FlexView className="error-page-content">
          <FlexView
            className="error-page-content-item error-page-content-left"
            column
          >
            <h1>{errorTitle}</h1>
            <p>{errorHint}</p>
            <a href="/">Torna alla home</a>

            <div className="status" style={{ marginTop: 'auto' }}>
              Codice errore: <b>{statusCode}</b>
            </div>
          </FlexView>

          <FlexView
            className="error-page-content-item error-page-content-rigth"
            hAlignContent="center"
            vAlignContent="center"
          >
            <DoodleAsset />
          </FlexView>
        </FlexView>
      </ErrorPageStyle>
    );
  }
}

export default ErrorPage;
