import * as React from 'react';

export default class DoodleAsset extends React.PureComponent {
  render() {
    return (
      <svg
        className="doodle-asset"
        viewBox="0 0 538 651"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(0 35)">
            <path
              d="M468 392.081771C468 387.066048 472.067607 383 477.087719 383c5.019009 0 9.08772 4.061885 9.08772 9.081771v125.278636c0 5.015723-4.067607 9.081771-9.08772 9.081771-5.019008 0-9.087719-4.061885-9.087719-9.081771V392.081771z"
              fill="#D3D3D3"
            />
            <path
              d="M69.0625 464.760751L4.98630236 412.862453c-2.8130584-2.278427-4.44723986-5.704993-4.44723986-9.32501V89.1554983L69.0625 126v338.760751z"
              fill="#C43B30"
            />
            <path
              d="M68 127.918392L200.212088 0h328.796455C533.974388 0 538 4.02765995 538 8.99512836V461.004872C538 465.972744 533.97234 470 529.004872 470H76.9951284C72.0272561 470 68 465.980172 68 461.004087V127.918392z"
              fill="#D94135"
            />
            <ellipse
              fill="#7E2403"
              cx="204.694403"
              cy="216.707866"
              rx="14.6944033"
              ry="14.7078658"
            />
            <path
              d="M276 383.96442c0-14.892038 12.061332-26.964421 26.939739-26.964421s26.93974 12.072383 26.93974 26.964421"
              fill="#7E2403"
            />
            <ellipse
              fill="#7E2403"
              cx="400.694403"
              cy="216.707866"
              rx="14.6944033"
              ry="14.7078658"
            />
            <path
              d="M69.1464844 126.443359l120.8512606 1.448725c4.971808.0596 9.002255-3.916461 9.002255-8.892795L198.999997 0 69.1464844 126.443359z"
              fill="#B43304"
            />
          </g>
          <ellipse
            fill="#F0ECEC"
            cx="290.175439"
            cy="633.892655"
            rx="129.175439"
            ry="16.8926554"
          />
          <g transform="translate(230 505)">
            <rect
              fill="#BDBDBD"
              width="18.1754386"
              height="135.141243"
              rx="9.0877193"
            />
            <path fill="#D3D3D3" d="M0 0h18.1754386v64.9717514H0z" />
            <rect
              fill="#BDBDBD"
              transform="rotate(90 18.175439 126.045198)"
              x="9.0877193"
              y="110.451977"
              width="18.1754386"
              height="31.1864407"
              rx="9.0877193"
            />
          </g>
          <g transform="translate(334 505)">
            <rect
              fill="#BDBDBD"
              width="18.1754386"
              height="135.141243"
              rx="9.0877193"
            />
            <path fill="#D3D3D3" d="M0 0h18.1754386v64.9717514H0z" />
            <rect
              fill="#BDBDBD"
              transform="rotate(90 18.175439 126.045198)"
              x="9.0877193"
              y="110.451977"
              width="18.1754386"
              height="31.1864407"
              rx="9.0877193"
            />
          </g>
          <path
            d="M44.5964005 356.2077178c3.0999548 5.845358 4.6499089 11.9541482 4.6499089 18.3265539 0 6.5330545-1.5228299 10.4488788-4.5685355 11.7475905-3.0457055 1.2987117-7.3119543-.0656258-12.7988742-4.0930535-3.9834419-2.923867-7.2499702-6.0935113-9.799683-9.509028-2.54971279-3.4155167-4.53365408-7.1029409-5.95188338-11.0623832C14.70910402 357.6579551 14 353.5006115 14 349.1452418c0-4.42676918.61223188-7.64101983 1.83671401-9.64284839 1.22448213-2.00182856 3.1619248-2.93142144 5.81238612-2.78880651 2.65046137.14261492 5.96736317 1.67583289 9.95080507 4.59969991 5.5644188 4.08431228 9.8965406 9.04907299 12.9964953 14.89443099z"
            fill="#B43304"
          />
          <path
            d="M21 362.0913243C21 357.0703246 25.067607 353 30.0877193 353c5.0190088 0 9.0877193 4.0765092 9.0877193 9.0913243V542.350854c0 5.020999-4.067607 9.091324-9.0877193 9.091324-5.0190088 0-9.0877193-4.076509-9.0877193-9.091324V362.0913243z"
            fill="#D3D3D3"
          />
          <path
            fill="#B43304"
            d="M.91796875 124.552734L133.578125.3671875 199 36.6035156 69.1230469 161.443359z"
          />
          <path
            d="M133.246094 1.84375h316.898709c7.538818 0 14.983879 1.67133548 21.79931 4.89370888L536 37.0234375H200L133.246094 1.84375z"
            fill="#C43B30"
          />
        </g>
      </svg>
    );
  }
}
